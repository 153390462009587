.mainpage {
    height: 100vh;
}

.mainpagecontain {
    padding: 40px 0;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.mainpagerow {
    display: block;
    margin-bottom: 5px;
}

.mainpageul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
}

.mainpageli{
    text-align: center;
}

.mainpageul a {
    text-decoration: none;
}

.homeIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.chatIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.callIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.stocksIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.healthIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.filesIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.billsIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.reportIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.galleryIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.settingsIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.taskIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.notificationIcon {
    width: 67px;
    height: 65px;
    border-radius: 15px;
    margin-left: 2px;
}

.zoomIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.settingIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.trainingIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
}

.calendarIcon {
    background-color: #fff;
    width: 65px;
    height: 65px;
    border-radius: 15px;
    text-align: center;
}

.calendarIcon h2 {
    font-size: 16px !important;
    font-weight: 700 !important;
    padding-top: 5px !important;
}

.calendarIcon h3 {
    font-size: 35px !important;
    font-weight: 700 !important;
    margin-top: -8px;
}

.calendarIcon h2 {
    color: red;
}


.ta-center {
    text-align: center;
}

.h-100vh{
    height: 100vh !important;
}





