.__balance {
    margin: 0 0 1em;
}

.__view {
    margin-top: 20px;
}

.avatar-file {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.avatar-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.modal-backdrop.show {
    z-index: 1;
}

.__footerbtn {
    margin: 10px 0px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit !important;
}

.__footerh6 {
    margin-bottom: 10px;
    margin-left: 7px;
}
.__footerbtn i{
    font-size: 16px !important;
    display: flex;
}
.__callbtn{
    cursor: pointer;
}

