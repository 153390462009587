    .balance-area-bg-home .balance-title h6 {
        color: #fff;
    }

    .balance-area-bg-home:after {
        background-color: rgb(206, 164, 73) !important;
    }

    #balance-section {
        background-color: rgb(176, 179, 178) !important;
        box-shadow: -2.61824px 26px 49px rgb(255 255 255 / 30%);
    }

    .ba-balance-inner {
        margin-left: 10px;
        margin-bottom: 20px;
        margin-right: 10px;
        padding: 20px;
        border-radius: 6px;
    }

    .transactionnew {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .pd-top-36 {
        padding-top: 35px;
    }

    .pd-top-40 {
        padding-top: 40px;
    }

    .section-title a {
        float: right;
        padding-top: 25px;
        color: #6236ff;
        text-decoration: none;
    }

    .ba-balance-inner .icon {
        height: 50px;
        width: 50px;
        display: inline-block;
        line-height: 48px;
        -webkit-box-shadow: 0px 0px 29px 0px rgb(252, 250, 237);
        box-shadow: 0px 0px 29px 0px rgb(252, 250, 237);
        border-radius: 50%;
        background: rgb(252, 250, 237);
        margin-bottom: 13px;
    }

    .ba-balance-inner .amount {
        color: rgb(252, 250, 237);
        margin: 5px;
    }

    .ba-bill-pay-inner .amount-inner h5 {
        float: left;
        display: inline-block;
        margin-bottom: 0;
        color: #000 !important;
        margin-top: 6px;
    }

    .readmore-btn {
        color: #000;
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
    }

    .ba-navbar .ba-main-menu ul li a:before {
        content: "";
        position: absolute;
        width: 15px;
        height: 2px;
        left: 0;
        top: 10px;
        background: rgb(176, 179, 178);
    }

    /* .ba-navbar .ba-main-menu ul li a {
  color: #000 !important;
} */

    .btn-ivory {
        background: rgb(206, 164, 73) !important;
        color: rgb(252, 250, 237) !important;
        padding: 10px 30px !important;
    }

    /*---------------------------------------
    ## Button
---------------------------------------*/

    .btn {
        border: 0;
        /* padding: 10px 30px; */
        height: 50px;
        font-size: 14px;
        -webkit-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        border-radius: 4px;
    }

    .__approvebtn {
        padding: 10px 30px !important;
    }

    .__denybtn {
        padding: 10px 30px !important;
    }

    h3.title.text-center {
        font-size: 20px !important;
    }

    ba-navbar.ba-navbar-show {
        background: #fcfaed !important;
    }

    .__btngroup {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    ._btnTodo {
        font-family: 'Lato', sans-serif !important;
        height: 50px;
        font-weight: 500 !important;
        border-width: 1px;
        color: #000;
        border-radius: 30px !important;
        background: rgb(252, 250, 237) !important;
    }

    ._btnRequest {
        font-size: 14px !important;
        font-family: 'Lato', sans-serif;
        width: 80%;
        height: 50px;
        border-width: 1px;
        color: #fff;
        font-weight: bold;
        border-radius: 40px;
        box-shadow: 3px 3px 3px 0px #8a2a21;
        text-shadow: 0px 1px 0px #810e05;
    }

    .btnrequest1 {
        background: #f9376c !important;
        border-radius: 30px !important;
        color: #fff !important;
    }

    ._btnTask {
        font-size: 14px !important;
        font-family: 'Lato', sans-serif;
        width: 60%;
        height: 50px;
        border-width: 1px;
        color: #fff;
        font-weight: bold;
        border-radius: 30px;
        /* box-shadow: 3px 3px 3px 0px #888888;
  text-shadow: 0px 1px 0px #888888; */
    }

    ._btnTask1 {
        background: rgb(206, 164, 73) !important;
        border-radius: 30px !important;
        color: #fff !important;
    }

    .__updateprofilebg {
        background-color: rgb(176, 179, 178);
    }

    ._aboutmain {
        margin-top: 5px;
        padding: 7px;
        line-height: 20px;
        border-radius: 3px;
        background-color: rgb(206, 164, 73) !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    ._aboutmain1 {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 7px;
        line-height: 20px;
        border-radius: 3px;
        color: #000;
        background-color: rgb(176, 179, 178) !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
    }

    .about-paragraph0 {
        font-weight: 700;
        font-size: 14px;
    }

    .about-detail {
        padding: 5px 10px 5px 15px;
    }

    .about-detailsp {
        font-size: 12px;
        font-weight: 700;
    }

    .new-about-era {
        margin-top: 60px;
    }

    .about-paragraph0 {
        /* color: rgb(0, 0, 0); */
        font-size: 13px;
    }

    .about-detailul {
        padding-left: 0 !important;
        font-size: 14px;
        font-size: 700;
    }

    .about-detailli1 {
        color: #fff;
    }

    .head6 {
        padding-top: 0px !important;
        font-size: 16px !important;
        font-size: 700 !important;
        color: #000;
    }

    .healthreport_ {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px 15px 0px;
        border-bottom: 2px solid #000;
    }

    .financereport_ {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px 15px 0px;
    }

    .a_about {
        /* text-decoration: none; */
        padding: 5px 10px 5px 10px;
        border-radius: 14px;
        text-align: center;
        background-color: rgb(176, 179, 178);
        color: #000;
    }

    /* icon transformation */

    .shadow-icon {
        font-size: 25px;
        background-color: rgb(206, 164, 73) !important;
        padding: 5px;
        color: rgb(252, 250, 237);
        border-radius: 5px;
    }

    .pain-icon {
        font-size: 25px;
        background-color: rgb(206, 164, 73) !important;
        padding: 5px;
        color: rgb(252, 250, 237);
        border-radius: 5px;
    }

    .habit-icon {
        font-size: 25px;
        background-color: rgb(206, 164, 73) !important;
        padding: 5px;
        color: rgb(252, 250, 237);
        border-radius: 5px;
    }

    .personality-icon {
        font-size: 25px;
        background-color: rgb(206, 164, 73) !important;
        padding: 5px;
        color: rgb(252, 250, 237);
        border-radius: 5px;
    }

    .motivation-icon {
        font-size: 25px;
        background-color: rgb(206, 164, 73) !important;
        padding: 5px;
        color: rgb(252, 250, 237);
        border-radius: 5px;
    }

    .health-icon {
        font-size: 22px;
        padding: 5px;
        border-radius: 5px;
        background-color: rgb(206, 164, 73) !important;
        color: rgb(252, 250, 237);
    }

    .finance-icon {
        font-size: 22px;
        padding: 5px;
        background-color: rgb(206, 164, 73) !important;
        color: rgb(252, 250, 237);
        border-radius: 5px;
    }

    .widget-head {
        margin-left: 20px;
        color: #000 !important;
    }

    .widget-icon {
        margin-top: 11px;
        margin-left: 7px;
    }

    .habits {
        background: rgb(206, 164, 73);
        color: rgb(252, 250, 237) !important;
    }

    .personalitytraits {
        background: rgb(176, 179, 178);
        color: #000 !important;
    }

    .motivation {
        background: rgb(206, 164, 73);
        color: rgb(252, 250, 237) !important;
    }

    .ba-balance-inner .title {
        color: #fff;
        padding: 0;
        margin: 0;
    }

    /* health */

    .col-health-section {
        text-decoration: none;
    }

    .__healthrow {
        display: grid !important;
    }

    /* user-setting */

    .bg-user-setting:after {
        background-color: rgb(206, 164, 73);
        background-size: cover !important;
        background-position: center !important;
        border-radius: 5px;
    }

    /* background color custom */

    .pd-top-15 {
        padding-top: 10px;
    }

    .bill-pay-area.pd-top-15 {
        height: 100vh !important;
    }

    .cart-area.pd-top-40 {
        background-color: rgb(252, 250, 237) !important;
        height: 100vh !important;
    }

    .history-area.pd-top-40 {
        height: 100vh !important;
        padding-bottom: 30px;
    }

    .goal-area.pd-top-36 {
        height: 100vh !important;
    }

    .add-balance-area.pd-top-40.__main {
        background-color: rgb(252, 250, 237) !important;
        height: 100vh !important;
    }

    .btn-grey {
        color: #000;
        background: rgb(176, 179, 178) !important;
    }

    .btn-gold {
        background: rgb(206, 164, 73) !important;
        color: #fff !important;
    }

    .btn-goldhome {
        background: rgb(206, 164, 73) !important;
        color: #fff !important;
        padding: 0 25px !important;
        border-radius: 25px !important;
    }

    .bg-ivory {
        background: rgb(252, 250, 237) !important;
        color: rgb(176, 179, 178);
    }

    .color-black {
        color: #000 !important;
    }

    .color-white {
        color: rgb(252, 250, 237) !important;
    }

    .color-grey {
        color: rgb(176, 179, 178) !important;
    }

    .balance-area.pd-top-40.mg-top-35 {
        margin-bottom: 30px;
    }

    /* footer */

    .footer-area {
        background: #fff;
        margin-bottom: 40px;
    }

    .reportcards {
        padding: 10px;
        height: 100vh;
    }

    .__reportcards {
        margin-top: 10px;
    }

    /* Home css */

    .ba-navbar .ba-main-menu {
        border-top: 1px solid #ececec;
        margin-top: 20px;
        padding: 20px;
        overflow-y: auto;
        height: 100%;
    }

    .section-title.style-three .title {
        float: none;
        padding-bottom: 15px;
    }

    .ba-navbar .ba-main-menu ul li a {
        position: relative;
        padding-left: 25px;
        display: block;
        text-decoration: none !important;
        -webkit-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }

    .footer-bottom ul li a {
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        text-decoration: none !important;
    }

    /* finances */

    .__basics {
        text-decoration: none !important;
    }

    ._finance-transaction {
        padding: 0;
    }

    ._finance-transaction h5.spend {
        background: #ffcad8;
        color: #ff396f;
    }

    ._finance-transaction h5.invest {
        background: #c8ffe2;
        color: #1dcc70;
    }

    .ba-single-transaction .spend {
        margin-top: -9px;
    }

    .ba-single-transaction .invest {
        margin-top: -9px;
    }

    .ba-single-transaction.style-two .spend {
        height: 100%;
        position: absolute;
        right: 0;
        top: 9px;
        -webkit-box-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 80px;
        border-radius: 5px;
    }

    .ba-single-transaction.style-two .invest {
        height: 100%;
        position: absolute;
        right: 0;
        top: 9px;
        -webkit-box-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 80px;
        border-radius: 5px;
    }

    #_expensechart {
        margin-top: 10px;
        border-bottom: 1px solid #ececec;
    }

    /* Report css */

    div.row_border {
        padding-bottom: 10px;
    }

    /* Circle container */

    @media (max-width: 576px) {
        .anim_container {
            width: 300px !important;
            height: 300px !important;
        }

        .anim_container .icon .imgBx {
            transform-origin: 170px !important;
            width: 50px !important;
            height: 50px !important;
        }

        .main_anim {
            min-height: 55vh !important;
        }

        .main_image {
            right: 0;
            position: relative;
            left: 0;
            top: -225px !important;
            width: 140px !important;
            left: 80px !important;
            height: 140px !important;
            border-radius: 50%;
            object-fit: cover !important;
        }

        .content_::before {
            inset: 30px !important;
            border-left: 3px solid #2196f3 !important;
            border-right: 3px solid #000000 !important;
        }

        .content_::after {
            inset: 60px !important;
            border-left: 3px solid #ff1d50 !important;
            border-right: 3px solid #000000 !important;
        }
    }

    .main_image {
        right: 0;
        position: relative;
        left: 0;
        bottom: 420px;
        width: 250px;
        left: 190px;
        height: 250px;
        border-radius: 50%;
        object-fit: cover;
    }

    .main_anim {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }

    .anim_container {
        position: relative;
        width: 600px;
        height: 600px;
        border: 3px solid #000000;
        border-radius: 50%;
    }

    .anim_container .icon {
        position: relative;
        width: 100%;
        left: -50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 5;
    }

    .anim_container .icon .imgBx {
        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 0 2px #222, 0 0 0 2px #fff;
        transform: rotate(calc(360deg / 8 * var(--i)));
        transform-origin: 340px;
        z-index: 100;
        overflow: hidden;
    }

    .anim_container .icon .imgBx img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: rotate(calc(-360deg / 8 * var(--i)));
        transition: 0.5s;
    }

    .content_::before {
        content: "";
        position: absolute;
        inset: 60px;
        border: 4px solid transparent;
        border-left: 4px solid #2196f3;
        border-right: 4px solid #000000;
        border-radius: 50%;
        animation: animate_01 5s linear infinite;
        z-index: 1;
        pointer-events: none;
    }

    @keyframes animate_01 {
        0% {
            rotate: 0deg;
        }

        100% {
            rotate: 360deg;
        }
    }

    .content_::after {
        content: "";
        position: absolute;
        inset: 120px;
        border: 4px solid transparent;
        border-left: 4px solid #ff1d50;
        border-right: 4px solid #000000;
        border-radius: 50%;
        animation: animate_02 2.5s linear infinite;
        z-index: 1;
        pointer-events: none;
    }

    @keyframes animate_02 {
        0% {
            rotate: 360deg;
        }

        100% {
            rotate: 0deg;
        }
    }

    .contentBx {
        position: absolute;
        transform: scale(0);
        transform: 0.5s;
    }

    .contentBx.active {
        transform: scale(1);
        opacity: 1;
        transition-delay: 0.5s;
    }

    ._commonsize {
        font-size: 30px;
    }

    .commona_popover {
        text-decoration: none;
        font-weight: 500;
        color: #000;
    }

    /* Report css */

    ._reportbgsha {
        border-radius: 1rem;
    }

    .d-flex.p-3.bg-white.justify-content-between.align-items-center.rounded-4 {
        box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
    }

    ._icon_button button i {
        font-size: 18px;
    }

    ._report_name {
        font-size: 16px;
        font-weight: 600;
    }

    .report_clodebtn {
        max-width: 100px;
        box-shadow: none;
        color: black;
    }

    /* Report css */

    .month_btn {
        font-size: larger;
        margin-right: 3px;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 7px;
        flex: 15.33% 0 0;
    }

    @media (max-width: 576px) {
        .month_btn {
            width: 20px;
        }
    }


    .year_btn {
        border: none;
        background-color: #fff;
        margin-bottom: 10px;
        margin-right: 5px;
        left: 1.2%;
        padding: 12px 16px;
        border-radius: 7px;
        font-size: 16px;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    }

    .report_container {
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
    }

    .buttons_cross {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ._xicon {
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px 15px;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    }

    ._dicon {
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px 15px;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    }

    .buttons_dot {
        display: flex;
        align-items: end;
        justify-content: end;
        margin-bottom: 10px;
    }

    h4.fina_nce {
        margin-bottom: 10px;
    }

    .modal-header._videoclose button.btn-close {
        font-size: 12px;
    }

    /* Health Report */

    ._healthreport_head {
        background: url("./assets/bg_report.png");
        background-size: contain;
        background-repeat: no-repeat;
    }

    #p_cashflow {
        font-size: 16px;
        font-weight: 600;
    }

    button.close._newclose {
        box-shadow: none;
        text-align: end;
        color: #111110;
        margin-right: 0px;
    }

    button.commonmodalbutton {
        padding: 7px 10px;
        border-radius: 30px;
        background: #111110ed;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        color: #fff;
    }

    input.input_style_.jadj.jkdju {
        font-size: 15px;
        font-weight: 600;
    }