/* ThemeSwitch.css */
.switch-container {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.switch-label {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.switch-label input {
	display: none;
}

.switch-slider {
	width: 4rem;
	height: 2rem;
	border-radius: 1rem;
	position: relative;
	margin-right: 1rem;
}


.switch-slider:before {
	content: "";
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background-color: #fff;
	transition: transform 0.3s ease;
}

.switch-label input:checked+.switch-slider:before {
	transform: translateX(1.75rem);
}

.switch-label-text {
	font-size: 1rem;
}