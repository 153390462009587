/* dark-theme */

.dark-theme {
    background-color: #111517 !important;
}

.dark-theme h1,
h2,
h3,
h4,
h5,
h6,
b {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.dark-theme p {
    color: #fff;
    font-family: 'Lato';
}

.dark-theme i {
    color: #fff;
}

.dark-theme strong {
    color: #fff;
    font-family: 'Lato';
}

.dark-theme btn button {
    background-color: #fcfaed;
}

.dark-theme h6.ta-center {
    color: #fff !important;
    font-family: 'Playfair Display', serif;
}

.dark-theme .calendarIcon h3 {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

.dark-theme .calendarIcon h2 {
    color: red !important;
    font-family: 'Playfair Display', serif;
}


/* User Setting CSS */

.light-theme h3.title {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}


/* Notifications CSS */

#notifications {
    height: 100vh !important;
}

.dark-theme h5.noti-title {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.noti-para {
    color: #252525 !important;
    font-family: 'Lato';
}

.dark-theme i.no-arrow {
    color: #252525 !important;
}

.pd-top-20 {
    padding-top: 20px;
}

.pd-top-25 {
    padding-top: 25px;
}

a.btn-purple.ml-2 {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Lato';
}

.light-theme b.modal-bold {
    color: #252525;
}


/* Health CSS */

.dark-theme .personal-summary li {
    color: #fff;
}

.dark-theme h6.__processwork {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

.dark-theme h6.doctor-detailsh6 {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.doctor-detailsp {
    color: #252525 !important;
    font-family: 'Lato';
}

.dark-theme p.doctor-detailsp1 {
    color: #252525 !important;
    font-family: 'Lato';
}

.dark-theme h6.health-head {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.dark-theme h4._health-report {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.dark-theme h4.get-care {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.dark-theme form.form-horizontal label {
    color: #fff;
}

.dark-theme button.btn.btn-default,
button#save-event {
    color: #fff;
}

i.medikits {
    color: green !important;
}

i.fa-phones {
    color: #7c00cf !important;
}

i.fa-calendars {
    color: #f9376c !important;
}

i.fa-ambulances {
    color: #6236ff !important;
}

i.fa-commenting {
    color: rgb(206, 164, 73) !important;
}

.report-list {
    padding-top: 10px;
}

._react_player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

._react_player>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.md-top-5 {
    margin-top: 5px;
}


/* Home Css Are started here */

.light-theme h6.home-h6 {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

h6.home-h6 {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.home-name {
    color: #252525 !important;
    font-family: 'Lato';
}

.dark-theme h3.title {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.mg-top-60 {
    margin-top: 60px;
}


/* Finance css are started from here */

.dark-theme h5.fina_nce {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.reactapexchart {
    border-bottom: 1px solid #ececec;
}

.dark-theme p.fin-tdate {
    color: #252525;
    font-family: 'Lato';
}

.section-title h5.fina_nce {
    margin-bottom: 10px;
    font-family: 'Playfair Display', serif;
}

.dark-theme span.apexcharts-legend-text {
    color: #fff !important;
    font-family: 'Lato';
}


/* Document css */

.dark-theme h4.fina_nce {
    color: #fff;
    font-family: 'Playfair Display', serif;
}


/* Event calendar css */

.dark-theme h5.calendar-heading5 {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.dark-theme Time.eventime {
    color: #fff;
}

.dark-theme label.label_event {
    color: #fff;
}

.dark-theme .event_input {
    background: #333;
    color: #fff;
    border-color: #777 !important;
}

.dark-theme input[type="time"]::-webkit-calendar-picker-indicator,
.dark-theme input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1)
}

.dark-theme div.calender-paragraph.__calendarContent {
    color: #fff;
}

.dark-theme span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    color: #fff;
}

.dark-theme button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    color: #fff;
}

.dark-theme div.react-calendar__month-view__weekdays__weekday {
    color: #fff;
}

.dark-theme div.day-view span {
    color: #fff;
}


/* Footer Css */

.light-theme i.fa-2-2x {
    color: #0d6efd;
}

.dark-theme i.fa-2-2x {
    color: #fff;
}

.light-theme i.fa-1-8x {
    color: #6236ff;
}

.dark-theme i.fa-1-8x {
    color: #fff;
}

.dark-theme p._footer-p {
    color: #fff;
    font-family: 'Lato';
}

.light-theme p._footer-p {
    color: #040404;
    font-family: 'Lato';
}


/* Zoom CSS */

.dark-theme p.zoom-time {
    color: #252525 !important;
    font-size: 12px;
}


/* Google calendar css */

.dark-theme span.rbc-label {
    color: #fff;
}

.light-theme span.rbc-label {
    color: #252525;
}

.dark-theme div.c-date {
    color: #fff;
}

.light-theme div.c-date {
    color: #252525;
}

.dark-theme button.btn.today_cal {
    color: #fff;
}

.light-theme button.btn.today_cal {
    color: #252525;
}


/* Calendar btn css */

#calendar_btn {
    background: none !important;
    box-shadow: none !important;
}

.dark-theme div.modal-content {
    background: #333;
}

.menu {
    position: absolute;
    list-style: none;
    width: 200px;
    margin: 0;
    padding: 0;
    bottom: -30px;
}

.menu a {
    font-size: 13px;
    font-weight: 600;
    color: #252525;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #fcfaed;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Lato';
}

ul.linkbtn1 {
    margin-top: 20px;
    font-family: 'Lato';
}

.menu_1 {
    margin-bottom: 20px;
    margin-top: -15px;
    font-family: 'Lato';
}


/* Login and Signup css */

.dark-theme span#dark_span {
    color: #fff;
}

.dark-theme h6.header-content {
    color: #fff;
    font-family: 'Playfair Display', serif;
}

.dark-theme div#ba-navbar {
    background-color: #111517;
    color: #fcfaed;
}

.light-theme div#ba-navbar {
    background-color: #fcfaed;
    color: #252525;
}

.dark-theme a.nav_a {
    color: #fff;
    font-family: 'Lato';
}

.dark-theme li.nav_a a {
    color: #fff;
    font-family: 'Lato';
}


/* Light theme are started from here */


/* light-theme*/

.light-theme {
    background-color: #fcfaed !important;
}

.light-theme h6 {
    color: #111517;
    font-family: 'Playfair Display', serif;
}

.light-theme h5 {
    color: #111517;
    font-family: 'Playfair Display', serif;
}

.light-theme h4 {
    color: #111517;
    font-family: 'Playfair Display', serif;
}

.light-theme h3 {
    color: #111517;
    font-family: 'Playfair Display', serif;
}

.light-theme h2 {
    color: #111517;
    font-family: 'Playfair Display', serif;
}

.light-theme h1 {
    color: #111517;
    font-family: 'Playfair Display', serif;
}

.light-theme p {
    color: #111517;
    font-family: 'Lato';
}

.light-theme b {
    color: #111517;
}

.light-theme img {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.light-theme .calendarIcon {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.light-theme .calendarIcon h3 {
    color: #252525 !important;
}

.light-theme .calendarIcon h2 {
    color: red !important;
    font-family: 'Playfair Display', serif;
}

.light-theme b.modal-bold {
    color: #252525 !important;
}

.light-theme btn button {
    background-color: #252525 !important;
}

.light-theme h6.ta-center {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

ul.list-group.mg-top-50.container {
    padding-left: 10px !important;
}

.dark-theme p.dblock.text-sm {
    color: #000;
    font-family: 'Lato';
}

p.dblock.text-sm {
    border: 2px solid;
    border-radius: 0px 10px 10px;
    padding: 5px;
    width: 150px;
    margin: 0px;
    font-family: 'Lato';
    font-size: 14px;
}

.col-2.img_col_2 {
    padding: 0;
    margin-top: -40px;
}

.dark-theme b.lifeNav {
    color: #000;
}

.dark-theme b.event_create_ {
    color: #000;
}

.dark-theme button.button_mason {
    background-color: black;
    color: #fff;
}

.light-theme button.button_mason {
    background-color: #333;
    color: #ddd;
}

.light-theme div.footer-bottom {
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.dark-theme div.footer-bottom {
    background-color: #333;
    border-top: 1px solid #222;
}

.dark-theme div.modal-maincont {
    background-color: #222;
}

.light-theme div.modal-maincont {
    background-color: #f4f4f4;
}

.light-theme div#header-section {
    background-color: rgb(206, 164, 73);
}

.dark-theme div#header-section {
    background-color: #333;
}

.light-theme div#send-money-section {
    background-color: rgb(206, 164, 73);
}

.dark-theme div#send-money-section {
    background-color: #333;
}

.dark-theme .btn-grey {
    color: #ddd !important;
    background: #333 !important;
}

.dark-theme div.report-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.dark-theme button.report_close {
    color: #fff;
}

.dark-theme ul#report_ul li {
    color: #fff;
}

.dark-theme div#_report_css i {
    color: #000 !important;
}

.dark-theme button.close.report_clodebtn {
    color: #ffffff !important;
}

.dark-theme h5.pdf_title {
    color: #000;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.date_des {
    color: #000;
    font-family: 'Lato';
}

.dark-theme div.container-repo {
    border: 2px solid #000;
}

.light-theme div.report-container {
    background-color: #fcfaed;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.light-theme h5.pdf_title {
    color: #000;
    font-family: 'Playfair Display', serif;
}

.light-theme p.date_des {
    color: #000;
    font-family: 'Lato';
}

.light-theme .switch-slider {
    background-color: #ccc;
}

.dark-theme .switch-slider {
    background-color: #555;
}

.light-theme .switch-label {
    color: #222;
}

.dark-theme .switch-label {
    color: #555;
}

.dark-theme h6.external_tit6 {
    color: #000000;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.apx8Vc.tjvcx.GvPZzd.cHaqb {
    color: #000000;
    font-family: 'Lato';
}

.dark-theme button.back_button {
    color: #fff;
}

.dark-theme p._auth_rize {
    color: #dddddd;
    font-family: 'Lato';
}

.light-theme p._auth_rize {
    color: lightslategray;
    font-family: 'Lato';
}

.dark-theme p.external_eauth {
    color: #dddddd;
    font-family: 'Lato';
}

.light-theme p.external_eauth {
    color: lightslategray;
    font-family: 'Lato';
}

.dark-theme div.sendNewMessage {
    background-color: #000;
}

.dark-theme div.sendNewMessage button {
    background-color: #000;
}

.dark-theme .sendNewMessage input {
    color: #fff;
}

.dark-theme h5.video-title {
    color: #fff !important;
    font-family: 'Playfair Display', serif;
}

.light-theme h5.video-title {
    color: #252525 !important;
    font-family: 'Playfair Display', serif;
}

.dark-theme h4.modal-title {
    color: #000;
    font-family: 'Playfair Display', serif;
}

.light-theme h4.modal-title {
    color: #000;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.modal-title {
    color: #000;
    font-family: 'Lato';
}

.light-theme p.modal-title {
    color: #000;
    font-family: 'Lato';
}

.dark-theme button.document-add {
    color: #fff !important;
}

.light-theme button.document-add {
    color: #fff !important;
}

.dark-theme button.document-upload {
    color: #fff !important;
}

.light-theme button.document-upload {
    color: #fff !important;
}

.dark-theme p.attachments {
    color: #000;
    font-family: 'Lato';
}

.light-theme p.attachments {
    color: #000;
    font-family: 'Lato';
}

.dark-theme p.document-wife {
    color: #000;
    font-family: 'Lato';
}

.light-theme p.document-wife {
    color: #000;
    font-family: 'Lato';
}

.dark-theme h4.modal-title {
    color: #000;
    font-family: 'Playfair Display', serif;
}

.light-theme h4.modal-title {
    color: #000;
    font-family: 'Playfair Display', serif;
}

.dark-theme p.modal-title {
    color: #000;
    font-family: 'Lato';
}

.light-theme p.modal-title {
    color: #000;
    font-family: 'Lato';
}

.dark-theme button.document-add {
    color: #fff !important;
}

.light-theme button.document-add {
    color: #fff !important;
}

.dark-theme button.document-upload {
    color: #fff !important;
}

.light-theme button.document-upload {
    color: #fff !important;
}

.dark-theme p.attachments {
    color: #000;
}

.light-theme p.attachments {
    font-family: 'Lato';
    color: #000;
}

.dark-theme p.document-wife {
    font-family: 'Lato';
    color: #000;
}

.light-theme p.document-wife {
    font-family: 'Lato';
    color: #000;
}


/* Zoom css */

.dark-theme h6._title-finance {
    color: black;
    font-family: 'Playfair Display', serif;
}

.light-theme h6._title-finance {
    color: black;
    font-family: 'Playfair Display', serif;
}

.dark-theme h4._meeting-rel {
    color: black;
    font-family: 'Playfair Display', serif;
}

.light-theme h4._meeting-rel {
    color: black;
    font-family: 'Playfair Display', serif;
}

.dark-theme p._meeting-relp {
    color: black;
    font-family: 'Lato';
}

.light-theme p._meeting-relp {
    color: black;
    font-family: 'Lato';
}

.dark-theme h6._name {
    color: black;
    font-family: 'Playfair Display', serif;
}

.light-theme h6._name {
    color: black;
    font-family: 'Playfair Display', serif;
}

.dark-theme p._attendance {
    color: black;
    font-family: 'Lato';
}

.light-theme p._attendance {
    color: black;
    font-family: 'Lato';
}

.dark-theme .zoom-tr th i {
    margin-right: 10px !important;
    font-size: 16px;
    font-weight: bold !important;
    color: black;
}

.dark-theme h5._finance_zoom {
    color: black;
    font-family: 'Playfair Display', serif;
}

.dark-theme span._start_zoom {
    color: black;
}

.light-theme h5._finance_zoom {
    color: rgb(0, 0, 0);
    font-family: 'Playfair Display', serif;
}

.dark-theme p.zoom_moda {
    color: black;
    font-family: 'Lato';
}

.light-theme p.zoom_moda {
    color: rgb(0, 0, 0);
}

.dark-theme h6.zoom-cli {
    color: black;
    font-family: 'Playfair Display', serif;
}

.light-theme h6.zoom-cli {
    color: rgb(0, 0, 0);
    font-family: 'Playfair Display', serif;
}

.light-theme span._start_zoom {
    color: rgb(0, 0, 0);
}

.light-theme .zoom-tr th i {
    margin-right: 10px !important;
    font-size: 16px;
    font-weight: bold !important;
    color: black;
}

.dark-theme button.__footerbtn {
    background: #666;
    color: #fff;
}

.light-theme button.__footerbtn {
    background: #fcfaed;
}

.dark-theme li#chatuserlist {
    margin-bottom: 10px;
    border-radius: 10px;
    background: #555;
    color: #fff;
    margin-top: 20px;
    box-shadow: rgb(255 255 255 / 20%) 0px 8px 24px;
}

.dark-theme li#chatuserlist a {
    gap: 10px;
    display: flex;
}

.dark-theme li#chatuserlist a span {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.light-theme li#chatuserlist {
    margin-bottom: 5px;
    margin-top: 20px;
    border-radius: 10px;
    background: #ffffff;
    color: #000000;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.light-theme li#chatuserlist a {
    gap: 10px;
    display: flex;
}

.light-theme li#chatuserlist a span {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
}


/* Task list */

.dark-theme th.task_listavtar {
    background: transparent;
}

.dark-theme td.task_listtext {
    background: transparent;
}

.light-theme .logo img {
    box-shadow: none !important;
}