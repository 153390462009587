.container .content {
	position: absolute;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	color: #f1f1f1;
	width: 100%;
	line-height: 10px;
}

.container img {
	vertical-align: middle;
}

.container {
	position: relative;
	max-width: 800px;
	margin: 0 auto;
}

.about-doctor {
	color: #f1f1f1;
	font-size: 14px;
}

.doctor-item {
	padding: 15px;
}

.doctor-summary {
	font-size: 12px;
	font-weight: 501;
	margin-bottom: 4px;
}

.content {
	padding: 10px;
}

.personal-summary1 {
	margin-bottom: 7px;
	font-size: 16px;
}

.avliability {
	margin-bottom: 7px;
	margin-top: 20px !important;
}

._service-list {
	margin-bottom: 7px;
	margin-top: 20px !important;
}