.main__chatlist {
  border-right: 1px solid #ebe7fb;
  padding: 0 30px 0 30px;

  width: 100%;
}

.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media all and (max-width: 440px) {
  .main__chatlist {
    padding: 0px;
  }
}

.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #dad9dd;
  outline: none;
}

.search_wrap {
  background-color: #e6e5ea;
  border-radius: 5px;
}

.search_wrap input {
  background-color: transparent;
  border: none;
  padding: 15px 15px;
  outline: none;
  width: 80%;
  padding-right: 0;
}

.search-btn {
  height: 46px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 20%;
  cursor: pointer;
  font-size: 20px;
}

.chatlist__items {
  margin-top: 30px;
  overflow: auto;
  max-height: calc(100vh - calc(100vh / 2));
}

.chatlist__item {
  display: flex;
  border-bottom: 1px solid #ebe7fb;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s;
  /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal;
  /* or: normal */
  animation-timing-function: cubic-bezier(0.88,
      0.19,
      0.37,
      1.11);
  /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both;
  /* or: backwards, both, none */
  animation-delay: 0.1s;
  /* or: Xms */
}

@keyframes showIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.chatlist__item:first-child {
  margin-top: 0;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  margin-right: 20px;
  position: relative;
}

.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #ceccd3;
  font-weight: 400;
  font-size: 12px;
  display: block;
}

.chatlist__item:hover,
.chatlist__item.active {
  background: #fff;

  border-radius: 10px;
}

.isOnline {
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  border-radius: 50%;
  border: 2px solid #fff;
}

.isOnline.active {
  background-color: tomato;
}

.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

span.badge.first_badge {
  position: absolute;
  top: 10px;
  padding-top: 0;
  height: 40px;
  margin: -20px 0px 0px 30px;
  background: #d8d8d8;
  text-align: center;
  border-radius: 30px 30px 30px 4px;
  color: white;
  font-size: 14px;
  width: 200px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media (max-width: 576px) {
  span.badge.first_badge {
    width: 90px;
  }
}

@media (max-width: 576px) {
  span.badge.second_badge {
    width: 90px !important;
  }
}

hr.hr_first {
  border-top: 3px solid #fff;
  opacity: 1 !important;
}

hr.hr_second {
  border-top: 3px solid #fff;
  opacity: 1 !important;
}

span.badge.second_badge {
  position: absolute;
  /* top: 10px; */
  bottom: -9px;
  right: 50px;
  padding-top: 0;
  height: 40px;
  margin: -20px 0px 0px 30px;
  background: #f96c648c;
  text-align: center;
  border-radius: 30px 5px 30px 30px;
  color: white;
  font-size: 14px;
  width: 200px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}