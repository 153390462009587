/* add new event */

.addevent-calendar {
    float: right;
    margin-top: -33px;
    font-size: 12px;
}

.addevent-calendar {
    border: 1em;
    cursor: pointer;
    outline: none;
    background-image: linear-gradient(45deg, #FF6E79, #FF8558);
    padding: 7px;
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
    -webkit-transition: box-shadow 0.25s;
    transition: box-shadow 0.25s;
    color: white;
    width: 100px;
}

/* modal css are started from here */

.closeon {
    border-radius: 5px;
}

.closes {
    font-size: 15px;
}

.col-xs-4 {
    padding-left: 15px;
    font-size: 13px;
    font-weight: 500;
}

#fname {
    margin-left: 15px;
    font-size: 12px;
}

.endsdate {
    margin-top: 15px;
}

#appt {
    margin-left: 20px;
}

#appts {
    margin-left: 15px;
}

.startdate {
    margin-top: 15px;
}

#textinput-title {
    width: 50%;
    margin-left: 15px;
}

.modalevent-title {
    font-size: 15px;
    font-weight: 500;
}

#Advisors-title {
    margin-left: 15px;
    font-size: 12px;
}

#message-area {
    margin-left: 15px;
    width: 80%;
    font-size: 13px;
}

.plus-icon {
    padding-right: 5px;
}

.button-save {
    background-image: linear-gradient(45deg, #FF6E79, #FF8558);

}

#calendar-section {
    margin-top: 35px;
}

.closes {
    width: 40px;
}

.react-calendar__navigation__label {
    width: 155px;
}

.myevent .calender-row {
    border: none !important;
    background: none !important;
    margin-bottom: 10px !important;
}

.myevent.cal-0 {
    border-radius: 8px !important;
    background-image: linear-gradient(45deg, #FF6E79, #FF8558);
}

.myevent.cal-1 {
    border-radius: 8px !important;
    background-image: linear-gradient(45deg, #5c48de, #1cbeca);
}

.myevent.cal-2 {
    border-radius: 8px !important;
    background-image: linear-gradient(45deg, #44cc86, #289a78);
}

.myevent.cal-3 {
    border-radius: 8px !important;
    background-image: linear-gradient(45deg, #00ff5b, #0041ff);
}

.myevent.cal-4 {
    border-radius: 8px !important;
    background-image: linear-gradient(45deg, #d74177, #ffe98a);
}

.myevent1.cal-0 {
    border-radius: 8px !important;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(45deg, #FF6E79, #FF8558);
}

.myevent1.cal-1 {
    border-radius: 8px !important;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(45deg, #5c48de, #1cbeca);
}

.myevent1.cal-2 {
    border-radius: 8px !important;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(45deg, #44cc86, #289a78);
}

.myevent1.cal-3 {
    border-radius: 8px !important;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(45deg, #00ff5b, #0041ff);
}

.myevent1.cal-4 {
    border-radius: 8px !important;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(45deg, #d74177, #ffe98a);
}

.previous-meeting2 {
    margin-top: -5px !important;
}

.health-section-area {
    margin-top: 5px !important;
}

.previous-meeting {
    margin-top: 5px;
}

#calender-section .react-calendar__month-view .react-calendar__month-view__days__day {
    font-size: 14px;
    font-weight: bold;
}

#calender-section .react-calendar__month-view__days__day--neighboringMonth {
    background-color: rgb(196 187 167) !important;
}

/* Event calendar */

.calendar-area{
    height: 100vh !important;
    padding-top: 10px;
}
