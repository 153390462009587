.transaction-section {
    margin-top: 30px;
    margin-bottom: 0;
}

.usersetting-section {
    margin-top: 15px;
    margin-bottom: 10px;
}

.carts-section .title {
    margin-top: 30px;
}

.carts-section .count-title {
    margin-top: 40px;
}

.container.about-container {
    margin-top: 50px;
}

.about-column {
    margin-top: 10px;
}

#single-send-money-section {
    margin-left: 10px;
}

.section-title.title1 {
    color: white;
}

input.form-control.p-4.rounded-pill._usersettingsinput {
    padding: 15px 20px !important;
    background: #fff;
    box-shadow: -2.61824px 26px 49px rgba(182, 186, 203, 0.3);

}



.container.contact-section {

    margin-top: 50px;
}

.container.notification-section {
    margin-top: 25px;
}

.goal-area.pd-top-36.mg-bottom-35 {
    padding-top: 40px;
}

.col-health-section {
    flex-basis: auto;
}

.fa-1-8x {
    color: #5885b5;
}

.fa.fa-user.fa-1-8x.my-profile {
    cursor: pointer;
}

.point-view {
    font-size: 8px !important;
    color: rgb(244 118 92);
    display: flex;
    justify-content: center;
    flex-basis: fit-content;
    margin: 2px;
}

.point-view>i {
    display: inline-block;
    margin: 1px;
}

.react-calendar__tile--active .point-view {
    color: #fff;
}

.send-money-slider-1.owl-carousel-1 {
    display: flex;
    justify-content: center;
}

.send-money-slider-1.owl-carousel-1 .item {
    background: #fff;
    flex: 1 auto;
    margin: 20px;
    display: flex;
    justify-content: center;
    box-shadow: -2.61824px 26px 49px rgb(0 0 0 / 30%);
    text-align: center;
    padding: 10px;
    border-radius: 14px;
}

.send-money-slider-1.owl-carousel-1 .single-send-money {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.send-money-slider-1.owl-carousel-1 .single-send-money .avatar {
    margin: auto;
}

.ssm-name {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 4px;
    font-weight: bold;
    font-size: 15px;
}

#bottom-navigation {
    display: flex;
    justify-content: center;
    padding: 0 20px !important;
    align-items: baseline;
    margin: -3px;
}

#bottom-navigation>li {
    flex: 1 auto;
}

#bottom-navigation i {
    font-size: 22px;
}

.__add_bal i {
    font-size: 30px;
}

.get-care {
    font-size: 18px;
    margin-top: 15px !important;
}

.client-problems {
    font-size: 18px;
    text-align: justify;
}

.doctor-solution {
    font-size: 18px;
    text-align: justify;
}

._health-report {
    font-size: 18px;
    margin-top: 15px !important;
}

.pd-bottom-10 {
    padding-bottom: 10px;
}

.connect-doctor {
    padding-top: 20px;
}

.doctor-title {
    margin-top: 45px;
}

.blog-slider {
    margin-bottom: 10px;
}

.health-page .blog-slider .owl-stage {
    padding-top: 10px;
    padding-bottom: 10px;
}

.health-page .blog-slider .single-blog1 {
    background: #fbfafa;
    padding: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -4px #000000;
}

#overlay2 .modal-dialog {
    margin-top: 10px;
}

.__notify {
    box-shadow: 0px 0px 12px -3px #000000;
}

.__notify .thumb {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50% !important;
    overflow: hidden;
    display: flex;
}

.__notify .thumb img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.__notify .amount-inner .btn {
    flex: 1 auto;
    margin-right: 10px;
}

.__notify .amount-inner .btn:last-child {
    margin-left: auto;
}

.__notify .amount-inner {
    display: flex;
}


/* health css are started from here */

.health-area.pd-top-36 {
    position: relative;
    max-width: 800px;
    margin: 0 auto;

}

.health-title {

    margin-top: 45px;
}

.titlehealth {
    height: 240px;
    width: 440px;
    margin-left: 0;
}


h6 {

    padding-top: 10px;
}

.health-head {
    font-size: 18px;
    margin-bottom: 10px;
}

.health-container {
    padding-bottom: 20px;
}

.advisor-list {
    display: flex;
    margin: auto;
    width: 50%;
    color: black;
}

.advisor-ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    background: #dadada;
    padding: 10px;
    border-radius: 50px;
}

.advisor-li {
    margin-left: 10px;
}

.advisor-p {
    color: black;
    text-align: center;
}

.advisor-head {
    color: black;
    padding-top: 0;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 10px;
}

.item-advisor1 {
    margin-left: 15px;
    background: #f4f4f4;
    padding: 10px 10px 8px 10px;
}

.item-advisor {
    margin-left: -35px;
    background: #f4f4f4;
    padding: 10px 10px 8px 10px;
}

.advisorimg {
    margin-left: 15px;
}

.doctor-body {
    padding: 30px;
}


.doctor-detailsh6 {
    font-size: 14px;

}

.doctor-detailsp {

    margin-top: 0px;
    font-size: 13px;
    font-weight: 470;
}

.doctor-detailsp1 {
    font-size: 11px;
    margin-top: 0px;
    margin-bottom: 10px;
}


.doctor-link {

    margin-top: -10px;
    font-size: 12px;
    color: white;
}

.doctorlink {
    text-align: center;
    background: #22CE9A;
    color: white;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 15px;
}

/* #send-money-sections {

    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

#send-money-sections {

    position: relative;
    max-width: 800px;
    margin: 0 auto;
} */

.health-area {
    background-color: #F6F6F6;
    display: flex;
}

.health-area2 {
    background: #F6F6F6;
}

.health-area3 {
    text-align: center;
    padding-bottom: 20px;
}

.medikits {
    font-size: 25px;
}

.fa-phones {
    font-size: 25px;
}

.fa-calendars {
    font-size: 25px;
}

.fa-ambulance {
    font-size: 25px;
}

.col-health {
    border: 1px solid;
    padding: 5px;
    border-radius: 5px;
    margin-top: 12px;
}

.col-health:hover {
    border-radius: 5px;
    background: #22CE9A;
    box-shadow: 3px 5px #888888;
    cursor: grab;
    border: none;
    color: white;
}


.paragraph {
    font-size: 13px;
    font-weight: 600;
    text-align: initial;
}

.col-health {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}

.col-health:hover {
    border-radius: 30px;
    background: #22CE9A;
    box-shadow: 3px 5px #888888;
    cursor: grab;
    border: none;
}

.__processwork {
    margin-bottom: 10px;
}

.__iprocess {
    margin-right: 15px;
}

.pd-top-10 {
    margin-top: 10px;
}


.__neweeventbtn {
    margin: 10px 0px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    color: inherit;
}

.__neweeventbtn:hover {
    background: #22CE9A;
    color: #fff;
}

label.form-labels {
    display: flex;
}

input#exampleInputEmail1 {
    border-radius: 5px;
}

i.fa.fa-phone-square.advisor-li1 {
    font-size: 30px;
    color: #6236FF;
}

i.fa.fa-commenting.advisor-li {
    font-size: 30px;
    color: #5285C4;
}

i.fa.fa-calendar.advisor-li {
    font-size: 30px;
    color: #1dcc70;
}

li.advisor-li {
    padding-inline: 15px;
}

button.ui.button {
    border-radius: 50px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;
    padding: 20px 25px !important;
}

.report-advisor {
    margin-top: 10px;
}

.__healmodal {
    text-align: justify;
}

._bank_account {
    position: relative;
    padding-bottom: 30px;
}

h2.add_account_btn {
    width: auto;
    position: absolute;
    right: 0;
    margin-top: 15px;
}

.form_header.modal-header .btn-close {
    display: inline-block;
    width: auto;
}

.is_default_card {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #fff;
    font-size: 20px;
}

.is_default_card.selected {
    color: #ffeb3b;
}

.preview-img-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, .9);
}

.preview-img-container .close-icon-x {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
    text-align: center;
    font-size: 22px;
    color: #ddd;
    border: 1px solid #555555;
}

.preview-img-container img {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    max-width: 90%;
    max-height: 90%;
}

.preview-img-container img.show {
    visibility: visible;
    opacity: 1;
}

/* Task Css are started from Here */

.task-page .blog-slider .owl-stage {
    padding-top: 10px;
    padding-bottom: 10px;
}

.task-page .blog-slider .single-blog1 {
    background: #fbfafa;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -4px #000000;
}

.card {
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
    gap: 5px;
}

.card_top {
    display: flex;
    gap: 5px;
}

.card_labels {
    flex: 1;
    display: flex;
    gap: 10px;
}

.labels {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 4px 8px;
    border-radius: 50px;
    background-color: #e3e3e3;
    color: #fff;
    font-size: 14px;
    width: fit-content;
}

.card_title h6 {
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.5rem;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
    --tw-text-opacity: 1;
}



.card_top_labels {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
    line-height: 21px;
}

.card_top_labels label {
    border-radius: 40px;
    padding: 4px 12px;
    background-color: gray;
    color: #fff;
    width: fit-content;
}

.card_top_more {
    width: 30px;
    height: 20px;
    cursor: pointer;
    position: relative;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.flex {
    display: flex;
}

.gap-2 {
    gap: .5rem !important;
}

.items-center {
    align-items: center;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between !important;
}

.p-1 {
    padding: .25rem !important;
}

.avatar-group {
    display: inline-flex;
}

.tooltip-wrapper {
    display: inline-flex;
}

.avatar-group-chained>.tooltip-wrapper {
    margin-left: -0.5rem;
}

.font-semibold {
    font-weight: 600;
    color: #111827;
}

.text-base {
    color: #111827;
}

.bg-red-100 {
    color: #FB888E;
    border-radius: 10px;
    border: 2px solid #FB888E;
    box-sizing: border-box;
    padding: 2px 10px;
}

.bg-medium-100 {
    color: #dd7b06;
    border-radius: 10px;
    border: 2px solid #dd7b06;
    box-sizing: border-box;
    padding: 2px 10px;
}

.bg-low-100 {
    color: #2563eb;
    border-radius: 10px;
    border: 2px solid #2563eb;
    box-sizing: border-box;
    padding: 2px 10px;
}

.bg-critical-100 {
    color: #ED0713;
    border-radius: 10px;
    border: 2px solid #ED0713;
    box-sizing: border-box;
    padding: 2px 10px;
}

.avatar-circle {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 0px 1px 7px -4px #000000;
    border: 1px solid #000;
}

.task_listavtar {
    width: 50px !important;
    padding-left: 15px !important;
}

.task_listavtar img {
    margin-top: 13px !important;
}


.task_listtext {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px !important;
}

td.task_listtext.task_lsit {
    padding-top: 15px;
    padding-bottom: 15px;
}

th.task_listavtar.task_lsit {
    padding-top: 10px;
    padding-bottom: 10px;
}

._contacttable {
    margin-top: 10px;
    padding: 0px 15px 0px 15px;
}

._contacttable tr {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.task_heading {
    display: flex;
    align-items: center;
}

.task_heading i {
    margin-right: 5px;
    font-size: 15px;
}

.task_heading1 {
    display: flex;
    align-items: center;
}

.task_heading1 i {
    margin-right: 5px;
    font-size: 10px;
}

._mytaskbody {
    box-shadow: 0 6px 30px rgba(182, 186, 203, 0.3);
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    padding: 25px;
}

._mytaskbody h5 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #060606;
    font-size: 1rem;
}

.j-content {
    justify-content: left !important;
}

.col-10.ml-n1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

p.dblock.text-sm1 {
    border: 2px solid;
    border-radius: 10px 0px 10px 10px;
    padding: 5px;
    width: 150px;
    margin: 0;
    font-family: 'Lato';
    text-align: right;
    font-size: 14px;
}

#list-group-item {
    gap: 3vmin;
    display: grid;
    line-height: 1.2rem;
}

#_modalstycre1 {
    height: 80vh !important;
    overflow-y: scroll !important;
}

/* Calendar css */


.addbillfield select {
    border-bottom: 1px solid rgba(34, 36, 38, .15) !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding-left: 1px !important;
    font-size: 14px !important;
    padding-top: 5px !important;
    font-weight: 500 !important;
    color: #000 !important;
}


.task_listtext {
    border: none !important;
}

.btn_send {
    font-size: 18px;
}