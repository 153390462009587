.main__chatcontent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 10px);
  padding-bottom: 0;
}

.padding_btn {
  padding: 10px;
}

@media all and (max-width: 440px) {
  .padding_btn {
    padding: 0px;
  }
}

.main__chatcontent .avatar-img img {
  width: 100%;
  height: 100%;
}

.content__header {
  padding: 4px 8px;
  border-bottom: 1px solid #ebe7fb;
}

.current-chatting-user {
  display: flex;
  align-items: center;
}

.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}

.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings .btn-nobg {
  color: #000;
}

.content__body {
  flex: 1 auto;
  overflow: auto;
  padding: 4px 8px;
}

.chat__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s;
  /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal;
  /* or: normal */
  animation-timing-function: cubic-bezier(0.88,
      0.19,
      0.37,
      1.11);
  /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both;
  /* or: backwards, both, none */
  animation-delay: 0.2s;
  /* or: Xms */
}

@keyframes showIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.chat__item .avatar {
  margin-right: 0px;
  margin-left: 20px;
  background: #fff;
  padding: 1px;
}

.chat__item__content {
  background-color: #4462ff;
  color: #fff;
  padding: 15px;
  border-radius: 10px 10px 0 10px;
  max-width: 50%;
  min-width: 215px;
}

.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}

.chat__item__content .chat__meta span {
  font-size: 12px;
  color: #ddd;
  user-select: none;
}

.chat__msg {
  user-select: none;
}

.chat__item.other {
  flex-direction: row-reverse;
  transform-origin: left;
}

.chat__item.other .chat__item__content {
  background-color: #fdfdfd;
  color: #000;
  border: 1px solid #efefef;
  border-radius: 10px 10px 10px 0;
  max-width: 50%;
}

.chat__item.other .avatar {
  margin-right: 20px;
  margin-left: 0px;
}

.chat__item.other .chat__item__content .chat__meta span {
  color: #d1d1d1;
}

#__camera-modal.modal {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

#__camera-modal.modal .modal-fullscreen {
  height: 100vh !important;
  margin-top: 0 !important;
}

.content__footer {
  margin-top: auto;
  position: static;
  z-index: 1;
}

.sendNewMessage {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 0;
  border-top: 1px solid #e9e5e5;
}

.sendNewMessage button {
  width: 36px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #4665ff;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.sendNewMessage button:hover {
  transform: scale(1.2);
}

.sendNewMessage button i {
  display: block;
}

.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}

#sendMsgBtn {
  background-color: #3b5bfe;
  color: #fff;
}

.settings {
  display: flex;
}

#footer-section {
  display: none;
}

.__attachment {
  display: flex;
}

.__attachment span {
  font-size: 12px;
  overflow-wrap: break-word;
  display: inline-block;
  word-break: break-all;
}

.settings .btn-nobg {
  color: #fff;
  background: #1565c0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 5px 0;
}

._3bC73 {
  min-height: 400px;
  text-align: center;
}

._1ceqH ._1Yplu ._2gd2_ {
  margin-left: 0 !important;
  margin-top: 5px !important;
}

._1YOWG audio {
  min-width: '320px';
}

.preview-img img {
  height: auto !important;
}

.chat__msg>* {
  max-width: 100%;
}

#camera-video {
  width: 100%;
  height: calc(100vh - 0px);
  object-fit: cover;
}

#__camera-modal .modal-dialog {
  margin: 0;
  padding: 0;
  --bs-modal-padding: 0;
  --bs-modal-margin: 0;
}

#__camera-modal .modal-content,
#__camera-modal .modal-body {
  padding: 0 !important;
  background: rgba(black, black, black, .8) !important;
  overflow-y: hidden;
}

.camera-action-buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  z-index: 1;
  width: 100%;
}

.camera-action-s-buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  z-index: 9999;
  width: 100%;
}

.btn-click {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  cursor: pointer;
  border: none;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

#screenshot {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
}

.camera-action-s-buttons button {
  background: #404040;
  color: #fff;
  font-size: 2rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
}

.gallery-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  cursor: pointer;
  border: none;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gallery-btn input {
  position: absolute;
  opacity: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

@media (max-width: 640px) {

  .gallery-btn,
  .camera-action-s-buttons button,
  .btn-click {
    width: 60px;
    height: 60px;
  }
}

.typing-block {
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: #333;
}

.dark-theme .typing-block {
  color: #ddd;
}

.typing-block .wave {
  position: relative;
  margin-left: 5px;
}

.typing-block .wave span {
  position: relative;
  display: inline-block;
  text-transform: lowercase;
  animation: animate 3s ease-in-out infinite;
  margin-right: 1px;
  font-weight: bold;
}

@keyframes animate {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-20px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}